import { Box, Container, Flex, Image } from "@theme-ui/components"
import React, { useState } from "react"
import { useMenu } from "../hooks/useMenu"
import { getHomePath, getSearchPath } from "../utils/path"
import { InboundLink } from "./link"
import { MagicLink } from "../utils/magicLink"
import LanguageSwitcher from "./languageSwitcher"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import Logo from "../images/logo.png"
import { motion, AnimatePresence } from "framer-motion"

const Nav = () => {
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = useMenu()

  menu.map(menuItem => {
    menuItem.treeChildren.sort((a, b) => a.position - b.position)
    menuItem.treeChildren.map(menuItem => {
      if (menuItem.treeChildren.length > 0) {
        menuItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })

  return (
    <Box as="nav">
      <Container variant="xl">
        <Box sx={{ py: 6, pb: 5 }}>
          <InboundLink to={getHomePath(locale)}>
            <Image src={Logo} sx={{ height: "50px" }} />
          </InboundLink>
        </Box>
        <Box
          sx={{
            backgroundColor: "light",
            borderBottom: "1px solid",
            borderColor: "light",
            width: "100%",
            zIndex: "9999",
          }}
        ></Box>
        <Flex
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            pt: [4],
            // backgroundColor: "light",
            // borderBottom: "1px solid",
            // borderColor: "lightGray",
            // position: "fixed",
            // width: "100%",
            // zIndex: "9999",
          }}
        >
          <Flex
            sx={{
              flexDirection: "row",
              padding: 0,
              margin: 0,
              listStyle: "none",
            }}
            as="ul"
          >
            {menu.map(item => (
              <TextComponent item={item} locale={locale} key={item.id} />
            ))}
          </Flex>
          <Flex
            sx={{
              flexDirection: "row",
              margin: 0,
              listStyle: "none",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <LanguageSwitcher />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

const TextComponent = ({ item, locale }) => {
  const [show, setShow] = useState(false)
  return (
    <Box
      as="li"
      key={item.id}
      sx={{ position: "relative", paddingY: 3, paddingX: 4 }}
      onMouseEnter={() => setShow(!show)}
      onMouseLeave={() => setShow(!show)}
    >
      {item.link ? (
        <MagicLink variant="links.nav" item={item.link} locale={locale} />
      ) : (
        <Box
          variant="links.nav"
          sx={{
            cursor: "default",
            color: "light",
          }}
        >
          {item.anchor}
        </Box>
      )}
      {item.treeChildren.length > 0 && (
        <AnimatePresence>
          {show && (
            <motion.div
                initial={{ y: 50,opacity: 0}}
                animate={{ y: 10, opacity: 1 }}
                transition={{ type: "spring", stiffness: 50 }}
                exit={{y: 50,opacity: 0 }}

                style={{
                  position: "absolute",
                  left: 0,
                  width: "max-content",
                }}
            >
              <Box
                as="ul"
                sx={{
                  listStyle: "none",
                  padding: 3,
                  margin: 0,
                  backgroundColor: "lightBackground",
                  boxShadow:
                    "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
                }}
              >
                {item.treeChildren.map(item =>
                  item.anchor ? (
                    <Box as="li" key={item.id} sx={{py:[2]}}>
                      {item.link ? (
                        <MagicLink sx={{ "&:hover" : {textDecoration : "underline" }}} item={item.link} locale={locale} />
                      ) : (
                        <Box sx={{ cursor: "default", color: "blue" }}>
                          {item.anchor}
                        </Box>
                      )}
                    </Box>
                  ) : null
                )}
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Box>
  )
}

export default Nav
