import React from "react"
import { Box, Text, Heading } from "@theme-ui/components"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  isList,
  renderRule,
} from "datocms-structured-text-utils"
import ImageGallery from "./blocks/imageGallery"
import { MagicLink } from "../utils/magicLink"
import NumbersGroup from "./blocks/numbersGroup"
import Embed from "./blocks/embed"

const RichContentStructuredText = ({ text, theme, props }) => {
  const componentTheme = theme || "light"
  const light = componentTheme === "light" ? "light" : "dark"
  const dark = componentTheme === "light" ? "dark" : "light"
  return (
    <Box
      sx={{
        "*:first-child": {
          marginTop: 0,
        },
        "*:last-child": {
          marginBottom: 0,
        },
      }}
    >
      {text.value && (
        <StructuredText
          data={text}
          renderLinkToRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsInternalLink":
                return <MagicLink item={record} lcoale={record.locale} />
              default:
                return null
            }
          }}
          renderInlineRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsInternalLink":
                return <Box>{
                  <MagicLink item={record} lcoale={record.locale} />
                }</Box>
              default:
                return null
            }
          }}
          renderBlock={({ record }) => {
            // console.log(record)
            switch (record.__typename) {
              case "DatoCmsImageGallery":
                return (
                  <Box mt={5} mb={5}>
                    <ImageGallery images={record.images} key={record.id} />
                  </Box>
                )
              case "DatoCmsNumbersGroup":
                return (
                  <Box mt={5} mb={5}>
                    <NumbersGroup numbers={record.numbers} key={record.id} />
                  </Box>
                )
              case "DatoCmsEmbed":
                return <Embed code={record.code} fullWidth={record.fullWidth} />
              default:
                return null
            }
          }}
          customRules={[
            renderRule(
              isHeading,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Heading
                        as={`h${node.level}`}
                        variant={`h${node.level}`}
                      >
                        {children}
                      </Heading>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isParagraph,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text as="p"  py={3} color={dark}>
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isList,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Box >
                        <Box
                          as={node.style === "numbered" ? "ol" : "ul"}
                          mb={3}
                          sx={{
                            color: dark,
                            listStyle: "none",
                            li: {
                              position:"relative",
                              width: "calc(100% - 16px)",
                              alignItems: "center",
                              "&::before": {
                                content: "'•'",
                                position: "absolute",
                                color: "text",
                                ml: "-2em"
                              },
                              p: {
                                p: 0,
                                mb: 0,
                              },
                            },
                          }}
                        >
                          {children}
                        </Box>
                      </Box>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
          ]}
        />
      )}
    </Box>
  )
}

export default RichContentStructuredText
