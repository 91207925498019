import React from "react"

export const languages = {
  en: {
    banner: "Come and meet us at the <strong>Industrial Valve Summit</strong> | <strong>May 25-26, 2022</strong> | Fiera di Bergamo, <strong>Stand 37 Hall B</strong>",
    locale: "en",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    copyright: "© Boscarol S.r.l. 2021  - Tutti i diritti riservati ",
    formInputTexts: {
      fullName: "Full name",
      company: "Company",
      address: "Street",
      city: "City",
      province: "Province",
      zipCode: "Zip Code",
      phone: "Phone",
      message: "Message",
    },
    sendRequestForm: "Submit",
    loading: "Loading",
    requestSent: "Request sent",
  },
  "en-us": {
    banner: "Come and meet us at the Industrial Valve Summit | May 25-26, 2022 | Fiera di Bergamo, Stand 37 Hall B",
    locale: "en-US",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    copyright: "© Boscarol S.r.l. 2021  - VAT 12655380157 - Tutti i diritti riservati ",
    formInputTexts: {
      fullName: "Full name",
      company: "Company",
      address: "Street",
      city: "City",
      province: "Province",
      zipCode: "Zip Code",
      phone: "Phone",
      message: "Message",
    },
    sendRequestForm: "Submit",
    loading: "Loading",
    requestSent: "Request sent",
  },
  it: {
    banner:"Vieni a incontrarci all'<strong>Industrial Valve Summit</strong> | <strong>25-26 maggio 2022</strong> | Fiera di Bergamo, <strong>Stand 37 Hall B</strong>",
    locale: "it",
    discoverMore: "Scopri di più",
    all: "Tutti",
    search: "Cerca",
    results: "risultati",
    noResults: "Nessun risultato",
    download: "Scarica",
    latestArticles: "Ultime news",
    copyright: "© Boscarol S.r.l. 2021 - P. IVA 12655380157 - Tutti i diritti riservati ",
    formInputTexts: {
      fullName: "Nome intero",
      company: "Azienda",
      address: "Via",
      city: "Città",
      province: "Provincia",
      zipCode: "CAP",
      phone: "Telefono",
      message: "Messaggio",
    },
    sendRequestForm: "Invia",
    loading: "Caricamento",
    requestSent: "Richiesta inviata!",
  },
}

export const i18nContext = React.createContext(languages.it)
