import {
  Box,
  Container,
  Flex,
  Grid,
  Text,
  Image,
  Heading,
} from "@theme-ui/components"
import React, { useEffect } from "react"
import { useCompany } from "../hooks/useCompany"
import { useFooter } from "../hooks/useFooter"
import { MagicLink } from "../utils/magicLink"
import { useSocial } from "../hooks/useSocial"
import { i18nContext } from "../context/i18nContext"
import {
  Facebook,
  Instagram,
  Twitter,
  Youtube,
  Linkedin,
  Mail,
  Phone,
  Map,
} from "react-feather"
import Logo from "../images/logo_white.png"
import { InboundLink } from "./link"
import { getHomePath, getSearchPath } from "../utils/path"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import RichContentStructuredText from "../components/richContentStructuredText"

const Footer = () => {
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const footer = useFooter()
  const company = useCompany()
  const social = useSocial()

  footer.map(footerItem => {
    footerItem.treeChildren.sort((a, b) => a.position - b.position)
    footerItem.treeChildren.map(footerItem => {
      if (footerItem.treeChildren.length > 0) {
        footerItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })
  console.log(footer)
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://cdn.iubenda.com/iubenda.js"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Box
      as="footer"
      sx={{
        backgroundColor: "primary",
        py: 7,
        color: "light",
        a: {
          color: "light",
        },
      }}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <Container variant="xl" sx={{ px: [3, 0] }}>
              <Grid columns={[1, "1fr 1.4fr 1fr 1fr"]} gap={[2, 6]}>
                <InboundLink to={getHomePath(locale)}>
                  <Image
                    src={Logo}
                    sx={{ height: "auto", width: ["50%", "100%"] }}
                  />
                </InboundLink>
                <Flex sx={{ flexDirection: "column", my: [2] }}>
                  <Box
                    sx={{
                      p: { fontSize: 1 },
                      "*:first-child": { mt: 0 },
                      ul: { pl: 0 },
                    }}
                    dangerouslySetInnerHTML={{ __html: company.body }}
                  />
                  <Flex
                    sx={{
                      flexDirection: "column",
                      margin: 0,
                      padding: 0,
                      mb: 0,
                      listStyle: "none",
                      a: {
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      },
                    }}
                    as="ul"
                  >
                    {company.icons[0].content.map(icon => {
                      return (
                        <Box
                          as="li"
                          sx={{
                            py: 2,
                            "&:last-child": { mb: 0 },
                            display: "flex",
                          }}
                        >
                          {icon.image && (
                            <Image
                              src={icon.image.url}
                              sx={{ filter: "invert(1)", mr: 3 }}
                            />
                          )}
                          {icon.viewMore && icon.viewMore.url && (
                            <MagicLink href={icon.viewMore.url}>
                              {icon.title}
                            </MagicLink>
                          )}

                          <Box sx={{ p: { py: [0] } }}>
                            <RichContentStructuredText
                              text={icon.body}
                              theme={"dark"}
                            />
                          </Box>
                        </Box>
                      )
                    })}
                  </Flex>
                </Flex>
                {footer.map((column, index) => (
                  <List key={column.id}>
                    {column.treeChildren.map(link => (
                      <Item>
                        {link.link ? (
                          <Text
                            sx={{
                              fontWeight: "normal",
                              mb: 2,
                              display: "block",
                            }}
                          >
                            <MagicLink item={link.link} />
                          </Text>
                        ) : (
                          <h2 style={{ marginTop: 0 }}>{link.anchor}</h2>
                        )}
                        {link.treeChildren.length > 0 && (
                          <List key={link.id}>
                            {link.treeChildren
                              .filter(link => link.anchor != null)
                              .map(link => (
                                <Item key={link.id}>
                                  {" "}
                                  {link.link ? (
                                    <MagicLink item={link.link} />
                                  ) : (
                                    link.anchor
                                  )}
                                </Item>
                              ))}
                          </List>
                        )}
                      </Item>
                    ))}
                    <Item>
                      {index === 0 && (
                        <Flex sx={{ flexDirection: "column", mt: [-3] }}>
                          <MagicLink
                            href={
                              t.locale === "it"
                                ? "https://www.iubenda.com/privacy-policy/75632309"
                                : "https://www.iubenda.com/privacy-policy/58058512"
                            }
                            className="iubenda-nostyle no-brand iubenda-embed"
                            title="Privacy Policy"
                            sx={{
                              fontWeight: "normal",
                              my: 2,
                              display: "block",
                            }}
                          >
                            Privacy Policy
                          </MagicLink>
                          <MagicLink
                            href={
                              t.locale === "it"
                                ? "https://www.iubenda.com/privacy-policy/75632309/cookie-policy"
                                : "https://www.iubenda.com/privacy-policy/58058512/cookie-policy"
                            }
                            className="iubenda-nostyle no-brand iubenda-embed"
                            title="Cookie Policy"
                            sx={{
                              fontWeight: "normal",
                              my: 2,
                              display: "block",
                            }}
                          >
                            Cookie Policy
                          </MagicLink>
                        </Flex>
                      )}
                    </Item>
                  </List>
                ))}
              </Grid>
            </Container>
            <Container sx={{ py: 0 }}>
              <Flex
                sx={{
                  display: "flex",
                  py: 1,
                  justifyContent: "space-between",
                  alignContent: "center",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    span: { color: "primary" },
                    fontWeight: "400",
                    fontSize: [1],
                    pt: [3, 0],
                  }}
                  dangerouslySetInnerHTML={{ __html: t.copyright }}
                ></Box>
                <Box>
                  <Grid columns={[4]} gap={[4]} sx={{ mt: [2, 0] }}>
                    {social.facebook && (
                      <Box>
                        <MagicLink target="_blank" href={social.facebook}>
                          <Facebook color="#FFF" />
                        </MagicLink>
                      </Box>
                    )}
                    {social.instagram && (
                      <Box>
                        <MagicLink target="_blank" href={social.instagram}>
                          <Instagram color="#FFF" />
                        </MagicLink>
                      </Box>
                    )}
                    {social.linkedin && (
                      <Box>
                        <MagicLink target="_blank" href={social.linkedin}>
                          <Linkedin color="#FFF" />
                        </MagicLink>
                      </Box>
                    )}
                    {social.youtube && (
                      <Box>
                        <MagicLink target="_blank" href={social.youtube}>
                          <Youtube color="#FFF" />
                        </MagicLink>
                      </Box>
                    )}
                  </Grid>
                </Box>
              </Flex>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        mb: 0,
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return <Box {...props} as="li" sx={{ py: 2, "&:last-child": { mb: 0 } }} />
}

export default Footer
