import { Box, Container, Flex } from "@theme-ui/components"
import React, { useState } from "react"
import { useMenu } from "../hooks/useMenu"
import { getHomePath } from "../utils/path"
import { InboundLink } from "./link"
import { MagicLink } from "../utils/magicLink"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import logoWhite from "../images/logo_white.png"
import { Image } from "@theme-ui/components"
import { ChevronDown, ChevronUp, Menu, X } from "react-feather"
import LanguageSwitcherMobile from "./languageSwitcherMobile"

const MobileNav = () => {
  const [show, setShow] = useState(false)
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = useMenu()

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Box>
        <Container sx={{ backgroundColor: "primary", paddingY: [2, 3] }}>
          <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <InboundLink to={getHomePath(locale)}>
                <Image src={logoWhite} sx={{ width: "160px" }} />
              </InboundLink>
            </Box>
            <Box onClick={() => setShow(!show)}>
              <Menu color="#FFF" />
            </Box>
          </Flex>
        </Container>
      </Box>
      {show && (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "primary",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 1000,
            height: "auto",
            maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <Container sx={{ backgroundColor: "primary", paddingY: [2, 3] }}>
            <Flex
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Box>
                <InboundLink to={getHomePath(locale)}>
                  <Image src={logoWhite} sx={{ width: "160px" }} />
                </InboundLink>
              </Box>
              <Box onClick={() => setShow(!show)}>
                <X color="#ffffff" />
              </Box>
            </Flex>
          </Container>
          <Flex
            sx={{
              flexDirection: "column",
              p: 2,
              margin: 0,
              alignItems: "start",
              listStyle: "none",
            }}
            as="ul"
          >
            {menu.map(item => (
              <TextComponent item={item} locale={locale} key={item.id} />
            ))}
          </Flex>
          <LanguageSwitcherMobile />
        </Box>
      )}
    </Box>
  )
}

const TextComponent = ({ item, locale }) => {
  const [show, setShow] = useState(false)
  const componentTheme = "light"
  const light = componentTheme === "light" ? "light" : "dark"

  return (
    <Box
      as="li"
      key={item.id}
      sx={{
        minHeight: "55px",
        position: "relative",
        fontWeight: "normal",
        color: light,
        "&:last-child": {
          ul: {
            right: 0,
          },
        },
        "& > a, & > div": {
          color: "light",
          paddingX: 3,
          paddingY: [3],
          display: "block",
        },
      }}
      onClick={() => setShow(!show)}
    >
      {item.link ? (
        <MagicLink variant="links.mobileNav" item={item.link} locale={locale} />
      ) : (
        <Flex
          sx={{
            display: "flex!important",
            cursor: "default",
            alignItems: "center",
          }}
        >
          <Box >
            {item.anchor}
          </Box>
          <Flex>
            {
              item.treeChildren.length > 0 && show ? 
              <ChevronUp size={16} />
              :
              <ChevronDown size={16} />
            }
          </Flex>
        </Flex>
      )}
      {item.treeChildren.length > 0 && show && (
        <Box
          as="ul"
          sx={{
            listStyle: "none",
            px: 4,
            margin: 0,
            backgroundColor: "darkBackground",
            position: "relative",
            width: "100%",
          }}
        >
          {item.treeChildren.map(item =>
            item.anchor ? (
              <Box
                as="li"
                key={item.id}
                sx={{
                  mt: 3,
                  mb: 3,
                  a: {
                    textDecoration: "none",
                    color: "light",
                  },
                }}
              >
                {item.link ? (
                  <MagicLink variant="links.magicNav" item={item.link} locale={locale} />
                ) : (
                  <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
                )}
              </Box>
            ) : null
          )}
        </Box>
      )}
    </Box>
  )
}

export default MobileNav
