import { Box } from "@theme-ui/components"
import React from "react"
import Nav from "./nav"
import MobileNav from "./mobileNav"

const Header = () => {
  return (
    <Box
      as="header"
      sx={{
        top: 0,
        position: "absolute",
        width: "100%",
        zIndex: 3,
      }}
    >
      <Box sx={{ display: ["none", "none", "none", "block"] }}>
        <Nav />
      </Box>
      <Box sx={{ display: ["block", "block", "block", "none"] }}>
        <MobileNav />
      </Box>
    </Box>
  )
}

export default Header
